
import React, { useState, useEffect, useRef } from 'react'
import Layout from '../components/layout'
import PromptButton from '../components/promptButton'
import Input from '../components/input'
import { Link } from 'gatsby'
import validator from 'validator'
import { EMPTY_FIELD, INVALID_EMAIL } from '../helpers/errors'
import styles from '../styles/index.module.css'
import { navigate } from '@reach/router'
import { StringParam, useQueryParam } from "use-query-params"

const Unsubscribe = () => {
    const [hash] = useQueryParam('hash', StringParam) // email hash
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState(false)
    const [fetching, setFetching] = useState(false)
    let submitButtonRef
    const [email, setEmail] =useQueryParam('email', StringParam)
    form.email = email// email hash
    // useEffect(() => {
    //     form.email = email
    // }, [email])
    // useEffect(() => {
    //     submitButtonRef.fetching(fetching)
    // }, [fetching, submitButtonRef])

    const handleChange = e => {
        form[e.target.name] = e.target.value
        setForm({...form})
    }

    const handleSubmit = () => {
        const hasErrors = !form.email?.length || !validator.isEmail(form.email ?? '')
        setErrors(hasErrors)
        if(!hasErrors) {
            setFetching(true)
            fetch('/.netlify/functions/unsubscribeByEmail', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify({
                    email: form.email,
                    digestId: 'unsubscribeall'
                })
            })
              .then(response => response.json())
              .then(data => {
                  setFetching(false)
                  if(data.success) {
                      navigate('/message?d=unsubsribe-success')
                  } else {
                      navigate('/message?d=unsubsribe-fail')
                  }
              })
        }
    }


    useEffect(() => {
        submitButtonRef.fetching(fetching)
    }, [fetching, submitButtonRef])

    return (
      <Layout>
          <div className={styles.form}>
              <span>Unsubscribe</span>
              <label>E-mail
                  { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                  <Input type={'email'} name={'email'} disabled={fetching} value={form.email ?? ''} error={errors && !form.email?.length? EMPTY_FIELD : errors && !validator.isEmail(form.email ?? '')? INVALID_EMAIL : undefined} onChange={handleChange}/>
              </label>
              <PromptButton ref={ref => submitButtonRef = ref} primary={true} handleClick={handleSubmit}>Unsubscribe</PromptButton>
              <hr/>
              <div className={styles.recoverLink}>
                  <Link to={`/`}>Back to Request access</Link>
              </div>
          </div>
      </Layout>
    )
}

export default Unsubscribe
